@import "../../_theme_variables";

/******************************************************************************
						Home Page Slider
*******************************************************************************/
#homepage-slider {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	z-index: 1;
	#homeslider {
		margin: 0 auto;
		padding: 0;
	}
	.homeslider-description {
		padding: 50px 50px;
		cursor: pointer;
		h2 {
			font-size: 2.7em;
			color: white;
			margin: 0;
			padding: 0;
			position:relative;
		}
		p {
			width: 50%;
			font-size: 1.15em;
			line-height: 1.3em;
			padding: 0.5em 0;
			color: white;
			position:relative;
		}
		button {
			font-size: 1.2em;
			text-transform: uppercase;
			position:relative;
		}
		@media (max-width: $screen-lg){
			padding: 20px;
			font-size: 0.8em;
		}
		@media (max-width: $screen-sm){
			padding: 30px;
			font-size: 0.7em;
			p {
				font-size: 1.5em;
			}
		}
		@media (max-width: $screen-xs){
			padding: 30px;
			font-size: 0.5em;
			p {
				font-size: 1.7em;
			}
		}
	}

}
/*****************************************************************************
						BXSlider Styles
********************************************************************************/
//RESET AND LAYOUT
#homepage-slider {
	.homeslider-container {
		position: relative;
	}
	.homeslider-image {
		position: absolute;
		left: 0;
		top: 0;
	}
	.homeslider-description {
		position: absolute;
		left: 0;
  		top: 0;
	}
	.bx-wrapper {
		position: relative;
		padding: 0;
		*zoom: 1;
		a {
			display: block;
		}
		img {
			display: block;	
			width: 100%;
			height: auto;
		}
	}
	// THEME
	.bx-wrapper {
		.bx-viewport {
			width: 100%;
		}
	}
	.bx-wrapper .bx-pager,
	.bx-wrapper .bx-controls-auto {
		position: absolute;
		bottom:30px;
		width:100%;
		z-index:999;
	}
	//LOADER
	.bx-wrapper .bx-loading {
		min-height: 50px;
		background: url(images/bx_loader.gif) center center no-repeat $light-background;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2000;
	}
	//PAGER
	.bx-wrapper .bx-pager {
		text-align: center;
		font-size: .85em;
		font-family: $font-family;
		font-weight: bold;
		color: #666;
		padding-top: 20px;
	}
	.bx-wrapper .bx-pager .bx-pager-item,
	.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
		display: inline-block;
		*zoom: 1;
		*display: inline;
	}
	.bx-wrapper .bx-pager.bx-default-pager a {
		background: none;
		border:3px solid #fff;
		text-indent: -9999px;
		display: block;
		width: 15px;
		height: 15px;
		margin: 0 5px;
		outline: 0;
		-moz-border-radius: 15px;
		-webkit-border-radius: 15px;
		border-radius: 15px;
	}
	.bx-wrapper .bx-pager.bx-default-pager a:hover,
	.bx-wrapper .bx-pager.bx-default-pager a.active {
		background: #fff;
	}
	//DIRECTION CONTROLS (NEXT / PREV)
	.bx-wrapper .bx-prev {
		right: 60px;
		left:auto;
		&:after {
			content: "\f053";	
		}
	}
	.bx-wrapper .bx-next {
		right: 10px;
		&:after {
			content: "\f054";	
		}
	}
	.bx-wrapper .bx-controls-direction a {
		position: absolute;
		bottom: 10px;
		top:auto;
		outline: 0;
		width: 52px;
		height: 52px;
		text-indent: -9999px;
		z-index: 9999;
		margin:0;
		line-height:20px;
		border: 2px solid $light-border-color;
		&:before {
			height: 48px;
			width: 48px;
			display: block;
			background: $light-background;
			content: ".";
			opacity: 0.14;
			text-indent: -9999px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
		}
		&:after { 
			display: block;
			width: 48px;
			height: 48px;
			color: $light-text-color;
			text-indent: 0;
			text-align: center;
			font-size: 21px;
			font-family: $font-icon;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			padding: 16px 0 0 0;
		}
		&:hover {
			&:before {
				background: $dark-background;
				opacity: 0.83;	
			}
		}
	}
	.bx-wrapper .bx-controls-direction a.disabled {
		display: none;
	}
	//AUTO CONTROLS (START / STOP)
	.bx-wrapper .bx-controls-auto {
		text-align: center;
	}
	.bx-wrapper .bx-controls-auto .bx-start {
		display: block;
		width: 10px;
		height: 11px;
		outline: 0;
		margin: 0 3px;
		&:before {
			content:'';
			font-family:$font-icon;
			color:#fff;
		}
	}
	.bx-wrapper .bx-controls-auto .bx-stop {
		display: block;
		width: 9px;
		height: 11px;
		outline: 0;
		margin: 0 3px;
		&:before {
			content:'';
			font-family:$font-icon;
			color:#fff;
		}
	}
	//PAGER WITH AUTO-CONTROLS HYBRID LAYOUT
	.bx-wrapper .bx-controls .bx-controls-auto {
		right: 15px;
		width: 35px;
		top:15px;
	}
	//IMAGE CAPTIONS
	.bx-wrapper .bx-caption {
		position: absolute;
		bottom: 0;
		left: 0;
		background: #666\9;
		background: rgba(80, 80, 80, 0.75);
		width: 100%;
	}
	.bx-wrapper .bx-caption span {
		color: $light-text-color;
		font-family: $font-family;
		display: block;
		font-size: .85em;
		padding: 10px;
	}
}
.ie8 {
	#homepage-slider{
		.bx-wrapper .bx-controls-direction a {
			&:before {
				background: $dark-background;
			}
		}
	}
}

#bx-pager-thumb {
	text-align:center;
	padding:10px 0;
	a {
		display:inline-block;
		max-width:150px;
		width:31%;
		img {
			max-width:100%;
			height:auto;	
		}
	}
}